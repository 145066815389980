import PropTypes from "prop-types";
import React from "react";

import S from "./picture-box.style";

const PictureBox = ({ fluid, rounded }) => (
    <S rounded={rounded}>
        <S.picture objectFit="cover" objectPosition="50% 50%" fluid={fluid} />
    </S>
);

PictureBox.propTypes = {
    fluid: PropTypes.object,
    rounded: PropTypes.bool,
};

PictureBox.defautProps = {
    rounded: false,
};

export default PictureBox;
