import { Box } from "@xstyled/styled-components";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";

import ArrowLink from "../../../common/ArrowLink/arrow-link";
import BestReviewCard from "../../../common/BestReviewCard/best-review-card";
import PictureBox from "../../../common/PictureBox/picture-box";
import Container from "../../../layout/Container/container";
import ContainerNarrow from "../../../layout/ContainerNarrow/container-narrow";
import Headline from "../../../typography/Headline/headline";
import TripAdvisorRate from "../TripAdvisorRate/tripadvisor-rate";

const ReviewPush = () => {
    const data = useStaticQuery(graphql`
        query {
            pavingStones: file(
                relativePath: { eq: "landing/paving-stones.jpg" }
                sourceInstanceName: { eq: "images" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 2000) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);
    return (
        <Box mb={5}>
            <Container>
                <Headline textAlign="center" variant="h2" as="h2" my={5}>
                    What they say about me
                </Headline>
            </Container>
            <Box position="relative" pt={5} pb={3} mb={5}>
                <PictureBox fluid={data.pavingStones.childImageSharp.fluid} />
                <ContainerNarrow>
                    <BestReviewCard shadow="dark" />
                </ContainerNarrow>
            </Box>
            <Container>
                <Box row justifyContent="center" mb={3}>
                    <TripAdvisorRate />
                </Box>
                <ArrowLink to="/reviews">See all reviews</ArrowLink>
            </Container>
        </Box>
    );
};

export default ReviewPush;
