import { Box } from "@xstyled/styled-components";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import useScript from "react-script-hook";

import { removeScriptBySrc } from "../../../../utils";

const TripAdvisorRateWide = ({ onReady }) => {
    const timeoutRef = useRef();
    const htmlRef = useRef();
    const scriptSrc =
        "https://www.tripadvisor.com/WidgetEmbed-cdsratingsonlywide?uniq=407&border=true&lang=en_US&locationId=21182183&border=true&display_version=2";

    const [loading] = useScript({
        src: scriptSrc,
        onload: function () {
            this.loadtrk = true;
        },
        checkForExisting: true,
    });

    useEffect(() => {
        /* eslint-env browser */
        if (htmlRef && !loading) {
            onReady();

            timeoutRef.current = setTimeout(() => {
                document
                    .querySelectorAll(".cdsROW, .cdsROWContainer, .cdsLocName")
                    .forEach((element) => element.removeAttribute("style"));
            }, 2000);
        }
        return () => {
            timeoutRef.current && clearTimeout(timeoutRef.current);
        };
    }, [htmlRef, loading, onReady]);

    useEffect(() => {
        return () => {
            removeScriptBySrc(scriptSrc);
        };
    }, []);

    return (
        <Box
            maxWidth={468}
            mb={3}
            minWidth={468}
            overflow="hidden"
            position="relative"
            ref={htmlRef}
            width={468}
        >
            <Box
                id="TA_cdsratingsonlywide407"
                className="TA_cdsratingsonlywide"
            >
                <ul id="oLViz6EUVGF" className="TA_links WWy1E7">
                    <li id="lXPOHeREQqDU" className="x0XjedlrWcr">
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.tripadvisor.com/Attraction_Review-g187147-d21182183-Reviews-Paris_Insight-Paris_Ile_de_France.html"
                        >
                            <img
                                src="https://www.tripadvisor.com/img/cdsi/img2/branding/v2/Tripadvisor_lockup_horizontal_secondary_registered-18034-2.svg"
                                alt="TripAdvisor"
                            />
                        </a>
                    </li>
                </ul>
            </Box>
        </Box>
    );
};

TripAdvisorRateWide.propTypes = {
    onReady: PropTypes.func,
};

export default TripAdvisorRateWide;
