import styled from "@xstyled/styled-components";
import { th } from "@xstyled/system";
import GatsbyImage from "gatsby-image";

const S = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    border-radius: ${({ rounded }) => th.size(rounded ? 8 : 0)};
`;

S.picture = styled(GatsbyImage)`
    height: 100%;
    width: 100%;
`;

export default S;
