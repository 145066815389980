import styled, { breakpoints, css, th } from "@xstyled/styled-components";
import GatsbyImage from "gatsby-image";
import { lighten } from "polished";

const S = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    width: 100%;
    flex-direction: column;
    margin-top: -100px;

    ${breakpoints({
        sm: `
            flex-direction: row;
        `,
    })}
`;

S.avatar = styled(GatsbyImage)`
    border-radius: 50%;
    min-width: 90px;
    min-height: 90px;
    margin: 0;
    padding: 0;
    border-color: ${(props) => lighten(0.3, th("colors.primary")(props))};
    border-style: solid;
    border-width: 3px;
    margin-right: 0;

    ${breakpoints({
        sm: css`
            margin-right: 4;
        `,
    })}
`;

S.text = styled.p`
    position: relative;
    font-style: italic;
    padding: 4;
    margin: 0;
    background-color: light;
    box-shadow: header;
    border-radius: 9;
`;

export default S;
