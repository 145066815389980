import { Box } from "@xstyled/styled-components";
import React from "react";

import Headline from "../../../typography/Headline/headline";
import Paragraph from "../../../typography/Paragraph/paragraph";

const InterestPush = () => (
    <Box>
        <Headline textAlign="center" variant="h2" as="h2" my={5}>
            Why choose Paris Insight?
        </Headline>
        <Box row>
            <Paragraph
                col={{ xs: 1, lg: 2 / 3 }}
                mx={{ xs: 0, lg: "17%" }}
                mb={5}
                textAlign="center"
            >
                <strong>Paris Insight</strong> is the “fruit” of a lifetime, a
                vibrant <strong>love affair with Paris</strong>. My walks
                immerse you in “1000 shades” of{" "}
                <strong>magical atmosphere</strong>. They weave together all the
                ingredients composing the charm of the City of Light. For the
                last 25 years <strong>Paris Insight</strong> has been the{" "}
                <strong>only company</strong> meeting the challenge of tours
                conceived on given themes.{" "}
                <strong>
                    All my tours are tailored to match your own uniqueness
                </strong>
                .
            </Paragraph>
        </Box>
    </Box>
);
export default InterestPush;
