import { graphql, useStaticQuery } from "gatsby";
import React from "react";

import S from "./virginia-card.style";

const VirginiaCard = () => {
    const data = useStaticQuery(graphql`
        query {
            avatar: file(
                relativePath: { eq: "virginia-profile.png" }
                sourceInstanceName: { eq: "images" }
            ) {
                childImageSharp {
                    fixed(width: 80, height: 80, quality: 100) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }
        }
    `);

    return (
        <S>
            <S.avatar fixed={data.avatar.childImageSharp.fixed} />
            <S.text>
                <strong>Hello, I am Virginia Dae</strong>, the founder of Paris
                Insight. I have been creating and leading my walks for private
                individuals &amp; small groups since 1996.
            </S.text>
        </S>
    );
};

export default VirginiaCard;
