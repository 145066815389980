import { Box } from "@xstyled/styled-components";
import React from "react";

import VirginiaCard from "../VirginiaCard/virginia-card";

const WelcomePush = () => (
    <Box row mb={5} justifyContent="center">
        <Box col={{ xs: 1, lg: 2 / 3 }} mb={4} mx={{ xs: 0, lg: "17%" }}>
            <VirginiaCard />
        </Box>
    </Box>
);
export default WelcomePush;
