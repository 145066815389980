import { Box, useBreakpoint } from "@xstyled/styled-components";
import { graphql, useStaticQuery } from "gatsby";
import React, { useMemo } from "react";

import TourCard from "../../../common/TourCard/tour-card";

const getToursLength = (breakpoint) => {
    switch (breakpoint) {
        case "xs":
        case "sm":
        default:
            return 2;
        case "md":
            return 3;
        case "lg":
        case "xl":
            return 4;
    }
};

const ToursSample = () => {
    const breakpoint = useBreakpoint();

    const data = useStaticQuery(graphql`
        query {
            tours: allMarkdownRemark(
                filter: {
                    frontmatter: {
                        type: { eq: "tour" }
                        id: {
                            in: [
                                "hemingway"
                                "midnight"
                                "morrison"
                                "custom-made"
                            ]
                        }
                    }
                }
                sort: { order: ASC, fields: frontmatter___order }
            ) {
                nodes {
                    frontmatter {
                        id
                        title
                        subTitle
                        path
                        new
                        featuredImage {
                            childImageSharp {
                                fluid(maxWidth: 400) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    const toursLength = useMemo(() => getToursLength(breakpoint), [breakpoint]);

    return (
        <Box px={0}>
            <Box row mx={{ xs: 0, sm: -3 }} mb={3}>
                {data.tours.nodes
                    .filter((tour, index) => index < toursLength)
                    .map(({ frontmatter }) => (
                        <Box
                            col={{
                                xs: 1,
                                sm: 1 / 2,
                                md: 1 / 3,
                                lg: 1 / 4,
                            }}
                            mb={4}
                            px={{ xs: 0, sm: 3 }}
                            key={frontmatter.id}
                        >
                            <TourCard
                                isNew={frontmatter.new}
                                picture={
                                    frontmatter.featuredImage.childImageSharp
                                        .fluid
                                }
                                title={frontmatter.title}
                                description={frontmatter.subTitle}
                                to={frontmatter.path}
                            />
                        </Box>
                    ))}
            </Box>
        </Box>
    );
};

export default ToursSample;
