import styled, { Box, css } from "@xstyled/styled-components";
import { breakpoints, th } from "@xstyled/system";

const S = styled(Box)`
    position: relative;
    border-radius: 8;
    padding: 4;
    box-shadow: ${({ shadow }) => th(`shadows.${shadow}`)};
    display: flex;
    flex-direction: column;
    margin-bottom: 88px;
    background-color: light;
`;

S.quote = styled.div(
    breakpoints({
        xs: css`
            display: flex;
            flex-direction: column-reverse;
        `,
        md: css`
            flex-direction: row;
        `,
    })
);

S.text = styled.p`
    font-size: 4;
    font-style: italic;
`;

S.decor = styled.div`
    position: relative;
    font-size: 270px;
    min-width: 100px;
    min-height: 80px;
    color: primary;
`;

S.decorLetter = styled.div`
    position: absolute;
    pointer-events: none;
    left: calc(100% - 104px);
    top: -94px;
`;

S.signature = styled.p`
    text-align: center;
    margin: 3 0;
`;

S.name = styled.span`
    font-family: "Josefin Sans", sans-serif;
    font-weight: bold;
`;

S.profession = styled.span`
    font-size: 3;
`;

S.logo = styled.div`
    width: 236px;
    height: 121px;
    margin: 0 auto -84px auto;
    background-color: blue;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
`;

export default S;
