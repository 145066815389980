import { Box } from "@xstyled/styled-components";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import Hero from "../components/common/Hero/hero";
import SectionSeparator from "../components/common/SectionSeparator/section-separator";
import Container from "../components/layout/Container/container";
import Layout from "../components/layout/Main/main";
import InterestPush from "../components/pages/index/InterestPush/interest-push";
import OfferPush from "../components/pages/index/OfferPush/offer-push";
import ReviewPush from "../components/pages/index/ReviewPush/review-push";
import WelcomePush from "../components/pages/index/WelcomePush/welcome-push";
import SEO from "../components/seo";

const IndexPage = ({ data }) => (
    <Layout>
        <SEO
            title="Small group and private customized tours"
            description="Hello, I am Virginia Dae, the founder of Paris Insight. I have been creating and leading my walks for private individuals &amp; small groups since 1996."
            image={data.heroLanding.publicURL}
        />
        <Box mb={5} zIndex={1}>
            <Hero fluid={data.heroLanding.childImageSharp.fluid} />
        </Box>
        <Container zIndex={2}>
            <WelcomePush />
            <InterestPush />
            <SectionSeparator />
        </Container>
        <ReviewPush />
        <Container zIndex={2}>
            <SectionSeparator />
            <OfferPush />
        </Container>
    </Layout>
);

IndexPage.propTypes = {
    data: PropTypes.object,
};

export const pageQuery = graphql`
    query HomePageQuery {
        heroLanding: file(
            relativePath: { eq: "landing/hero.png" }
            sourceInstanceName: { eq: "images" }
        ) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;

export default IndexPage;
