import { Box } from "@xstyled/styled-components";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";

import ArrowLink from "../../../common/ArrowLink/arrow-link";
import Headline from "../../../typography/Headline/headline";
import Paragraph from "../../../typography/Paragraph/paragraph";
import ToursSample from "../ToursSample/tours-sample";

const OfferPush = () => {
    const data = useStaticQuery(graphql`
        query {
            nbTours: allMarkdownRemark(
                filter: { frontmatter: { type: { eq: "tour" } } }
            ) {
                totalCount
            }
        }
    `);

    return (
        <Box mb={5}>
            <Headline textAlign="center" variant="h2" as="h2" my={5}>
                Find another way to discover Paris
            </Headline>
            <Box row>
                <Box
                    col={{ xs: 1, lg: 2 / 3 }}
                    mx={{ xs: 0, lg: "17%" }}
                    mb={5}
                    textAlign="center"
                >
                    <Paragraph>
                        There is{" "}
                        <strong>
                            no end to what Paris Insight can offer you
                        </strong>
                        .
                    </Paragraph>
                    <Paragraph>
                        <strong>Follow in the footsteps</strong> of Ernest
                        Hemingway, Scott Fitzgerald, James Joyce, Gertrude
                        Stein, Pablo Picasso, Man Ray, Oscar Wilde, Jim
                        Morrison, Paul Gauguin, Delacroix, Marc Chagall, Amadeo
                        Modigliani and many others. Paris Insight will guide you
                        to the places where they gathered and which they
                        immortalized in their works. Find out where episodes of
                        Victor Hugo’s novel <strong>Les Misérables</strong> took
                        place. Bring the{" "}
                        <strong>revolutionary Paris of 1830</strong> to light.
                        Stroll through the typical{" "}
                        <strong>open street markets</strong>, full of life and
                        color. Discover new worlds as you slip silently into{" "}
                        <strong>mysterious courtyards</strong> that Paris
                        preserves so carefully.
                    </Paragraph>
                    <Paragraph>
                        The Beginner’s Tour is ideal for new visitors. The
                        combination of two tours (Marais - Saint Germain) will
                        put an end to the confusion lots of visitors have
                        between the Right and Left Bank.
                    </Paragraph>
                    <Paragraph>
                        You are unique.{" "}
                        <strong>My tours are as unique as you</strong>.
                    </Paragraph>
                </Box>
            </Box>
            <ToursSample />
            <ArrowLink to="/tours">
                See all {data.nbTours.totalCount} tours
            </ArrowLink>
        </Box>
    );
};

export default OfferPush;
