import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import React from "react";

import S from "./best-review-card.style";

const BestReviewCard = ({ shadow }) => {
    const data = useStaticQuery(graphql`
        query {
            logo: file(
                relativePath: { eq: "reviews/sydney_morning_herald.png" }
                sourceInstanceName: { eq: "images" }
            ) {
                childImageSharp {
                    fixed(width: 236, height: 121) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }
        }
    `);

    return (
        <S shadow={shadow}>
            <S.quote>
                <S.text>
                    I like to take a half-day walking tour when I get to a new
                    city and the one that stands out is the Paris walks with
                    Virginia Dae, who runs <strong>Paris Insight</strong>. She
                    is a wonderful guide and her theme walks give you a great
                    feel for life in the city and its illustrious past, as well
                    as present-day realities.
                </S.text>
                <S.decor>
                    <S.decorLetter>”</S.decorLetter>
                </S.decor>
            </S.quote>
            <S.signature>
                <S.name>Michael Gebicki</S.name>
                <S.profession>
                    , travel writer and photographer for “The Sydney Morning
                    Herald”
                </S.profession>
            </S.signature>
            <S.logo>
                <Img fixed={data.logo.childImageSharp.fixed} />
            </S.logo>
        </S>
    );
};

BestReviewCard.propTypes = {
    shadow: PropTypes.oneOf(["regular", "dark"]),
};

BestReviewCard.defaultProps = {
    shadow: "regular",
};

export default BestReviewCard;
